<template>
    <el-dialog :title="title" width="40%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form size="small" :model="inputForm" ref="inputForm" :rules="dataRule" v-loading="loading"
                 label-width="100px">
            <el-form-item label="授予角色：" prop="roleId">
                <el-select v-model="inputForm.roleId" placeholder="请选择" filterable multiple collapse-tags clearable class="w100i">
                    <el-option v-for="item in roleOpt" :key="item.id" :label="item.name"
                               :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="有效期：" prop="effectiveTime">
                <el-date-picker v-model="inputForm.effectiveTime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                format="yyyy-MM-dd HH:mm:ss"
                                type="datetimerange" clearable start-placeholder="开始日期"
                                end-placeholder="结束日期" class="w100i">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">取消</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>保存</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data() {
            return {
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    roleId: [],
                    effectiveTime: [],
                    effectiveStartTime: '',
                    effectiveEndTime: '',
                },
                roleOpt: [],
                dataRule: {
                    roleId: [
                        {required: true, message: '授予角色不能为空', trigger: 'change'},
                    ],
                    effectiveTime: [
                        {required: true, message: '有效期不能为空', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            init(method, id) {
                this.getSysRole()
                this.loading = false
                this.method = method
                this.inputForm.effectiveTime = []
                this.inputForm.id = id ? id : ''
                if (method === 'add') {
                    this.title = '新增临时权限'
                } else if (method === 'edit') {
                    this.title = '修改临时权限'
                } else if (method === 'view') {
                    this.title = '查看临时权限'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if(this.method != 'add') {
                        this.getInfo()
                    }
                })
            },
            // 获取详情
            getInfo() {
                this.loading = true
                this.$axios(this.api.auth.systemporaryauthorityGetById + '/' + this.inputForm.id).then(data => {
                    if (data.status) {
                        this.inputForm.roleId = data.data.roleId
                        this.inputForm.effectiveTime.push(data.data.effectiveStartTime)
                        this.inputForm.effectiveTime.push(data.data.effectiveEndTime)
                    }
                    this.loading = false
                })
            },
            // 获取角色
            getSysRole() {
                this.$axios(this.api.auth.sysroleList, {
                    name: '',
                    current: 1,
                    size: 999,
                }).then(data => {
                    if (data.status) {
                        this.roleOpt = data.data.records
                    }
                })
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        let api = this.api.auth.systemporaryauthoritySave,
                            type = 'post'
                        if (this.method == 'edit') {
                            api = this.api.auth.systemporaryauthorityUpdateById
                            type = 'put'
                        }
                        let {effectiveTime} = this.inputForm
                        let p = JSON.parse(JSON.stringify(this.inputForm))
                        p.effectiveStartTime = effectiveTime ? effectiveTime[0] : ''
                        p.effectiveEndTime = effectiveTime ? effectiveTime[1] : ''
                        delete p.effectiveTime
                        if(new Date(p.effectiveStartTime) < new Date()) {
                            this.$message.error('有效期必须大于或等于当前时间')
                            return
                        }
                        this.loading = true
                        this.$axios(api, p, type).then(data => {
                            this.loading = false
                            if (data && data.status) {
                                this.$message.success(data.msg)
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error(data.msg)
                            }
                        })
                    }
                })
            },
        },
    }
</script>

<style scoped>

</style>
